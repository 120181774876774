<template>
  <div class="sortable">
    <div
      v-if="enableExport"
      class="d-flex align-items-center justify-content-start mb-1"
    >
      <CIcon name="cil-cloud-download" />
      <CDropdown
        toggler-text="Exportar tabela "
        placement="bottom-end"
        add-toggler-classes="p-0"
      >
        <CDropdownItem @click="exportTable('json')"> JSON </CDropdownItem>
        <CDropdownItem @click="exportTable('csv')"> CSV </CDropdownItem>
        <CDropdownItem @click="exportTable('xls')"> XLS </CDropdownItem>
      </CDropdown>
    </div>
    <table class="table table-striped table-hover border">
      <thead>
        <tr>
          <td class="table-drag"></td>
          <td
            v-for="(field, index) in fields"
            :key="`item-${index}`"
            :style="field._style"
          >
            <span>{{ field.label }}</span>
          </td>
        </tr>
      </thead>
      <draggable
        :list="items"
        :delay="10"
        class="w-auto"
        tag="tbody"
        ghost-class="ghost"
        @end="handleEnd"
      >
        <tr v-for="(item, index) in items" :key="`item-${index}`">
          <td class="table-drag">
            <CIcon name="cil-menu" class="mr-3" />
          </td>
          <template v-for="(field, fieldIndex) in fields">
            <td
              v-if="field.key === 'status'"
              :key="`field-${fieldIndex}`"
              :style="field._style"
            >
              <CBadge :color="getBadge(get(items[index], field.key), '')">
                {{ get(items[index], field.key) ? 'Ativo' : 'Inativo' }}
              </CBadge>
            </td>
            <td v-else :key="`field-${fieldIndex}`" :style="field._style">
              <div class="d-flex align-items-center">
                <img
                  v-if="field.thumb"
                  :src="items[index].thumb"
                  width="40"
                  height="40"
                  class="mr-3 thumb"
                />
                {{ get(items[index], field.key, '') }}
              </div>
            </td>
          </template>
        </tr>
        <tr v-if="items.length === 0">
          <td :colspan="fields.length + 1" class="w-100">
            <div class="text-center">
              Nenhum registro foi encontrado! Verifique os dados da sua pesquisa
              ou crie um novo registro.
            </div>
          </td>
        </tr>
      </draggable>
    </table>
  </div>
</template>

<script>
import { get } from 'lodash'
import draggable from 'vuedraggable'
import exportFromJSON from 'export-from-json'

/**
 * @see https://www.npmjs.com/package/vuedraggable
 */
export default {
  components: {
    draggable
  },

  props: {
    /**
     * Array of objects, where each object represents one row in table.
     */
    items: {
      type: Array,
      default: () => {
        return []
      }
    },

    /**
     * Prop for table columns configuration. If prop is not defined, table will display columns based on the first item keys, omitting keys that begins with underscore (e.g. '_classes')
     */
    fields: {
      type: Array,
      default: () => {
        return []
      }
    },

    /**
     * Enable table export to json, csv and xls
     */
    enableExport: {
      type: Boolean,
      default: true
    },

    /**
     * Enable which type of component status will be displayed
     * if true dispaly as switch component else as badge component
     */
    enableSwitchStatus: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      updateForm: ''
    }
  },
  created() {},

  methods: {
    /**
     * Get method from lodash
     * @see https://lodash.com/docs/#get
     * @public
     */
    get,

    /**
     * Get the badge by status
     * @param {String} status User status
     * @public
     */
    getBadge(status) {
      return status ? 'success' : 'secondary'
    },

    /**
     * onEnd function is triggered when the user finishes dragging the item
     * @param {Event} _event Draggable event
     * @public
     */
    // eslint-disable-next-line no-unused-vars
    handleEnd(event) {
      this.$emit('endDragging', this.items)
    },

    /**
     * Export to json, csv, xls files from JSON
     * @public
     */
    exportTable(type) {
      const data = this.items
      const fileName = 'download'
      const exportType = type

      exportFromJSON({ data, fileName, exportType })
    }
  }
}
</script>

<style lang="scss">
.sortable {
  tbody {
    display: flex;
    flex-wrap: wrap;
  }

  thead {
    display: flex;

    tr {
      cursor: initial;

      &:hover {
        background-color: initial !important;
      }
    }

    td {
      font-weight: bold;
    }

    td.m-55 {
      margin-left: 55px;
    }
  }

  table thead,
  .table thead,
  .datatable thead {
    background-color: #3c4b64;
    color: #fff;
  }

  .c-switch {
    transform: scale(0.7) translate(-10px, 5px);
    margin-left: 0px !important;
  }

  .table-actions {
    .table-action {
      padding-right: 10px;

      &:last-child {
        padding-right: 0px;
      }

      a {
        color: inherit;
      }
    }
  }

  tr {
    cursor: move;
    flex: 1 1 100%;
    display: flex;
    align-items: center;

    &:hover {
      background-color: rgba(249, 236, 133, 0.2) !important;
    }
  }

  td {
    border: none !important;
  }

  .dropdown {
    button {
      height: 45px;
      padding: 0px !important;
    }
  }

  .thumb {
    object-fit: contain;
  }
}

.ghost {
  border-left: 6px solid $secondary-base;
  box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.15);
  opacity: 0.7;
}

.badge.badge-primary {
  background-color: $success-dark !important;
}

.table-drag {
  width: 5%;
}
</style>
