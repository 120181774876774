<template>
  <div id="order-banners">
    <!-- Content -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">Banners Home - Ordernar</h4>
          </CCardHeader>

          <CCardBody>
            <!-- Sortable -->
            <CRow>
              <CCol col="12">
                <Sortable
                  :items="datatable.items"
                  :fields="datatable.fields"
                  :enable-switch-status="false"
                  :enable-export="false"
                  @endDragging="endDragging"
                >
                </Sortable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Sortable from '@/components/ui/Sortable'
import { orderBy, debounce } from 'lodash'
import BannerHomeService from '@/services/banner-home.service'

export default {
  metaInfo: {
    title: 'Banners',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_banners')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    Sortable
  },

  data() {
    return {
      datatable: {
        items: [],
        fields: [
          {
            key: 'status',
            label: 'Status',
            sorter: false,
            _style: 'width: 8%;'
          },
          {
            key: 'heading',
            label: 'Banner',
            sorter: false,
            thumb: true
          }
        ]
      },
      query: {
        search: '',
        page: 1,
        perpage: localStorage.getItem('perPageItem')
          ? parseFloat(localStorage.getItem('perPageItem'))
          : 10,
        pagination: false,
        status: true,
        sorter: {
          asc: false,
          column: ''
        }
      },
      processing: false
    }
  },

  async created() {
    await this.updateDataTable()
  },

  methods: {
    async updateDataTable() {
      const response = await BannerHomeService.getAll(this.query)

      if (response.data) {
        const newItems = response.data.map((item) => {
          return {
            ...item,
            thumb: item.medias.length > 0 ? item.medias[0].url : null,
            status: item.status === 1
          }
        })

        this.datatable.items = orderBy(newItems, ['order'])
      }
    },

    endDragging: debounce(function (items) {
      this.datatable.items = items
      BannerHomeService.updateBannerOrders(this.datatable.items)

      this.$notify({
        group: 'notifications',
        type: 'success',
        text: 'Ordenação alterada com sucesso.'
      })
    }, 1000)
  }
}
</script>

<style lang="scss">
#order-banners {
  .orderButton {
    margin-right: 10px;
  }

  .thumb {
    width: 80px !important;
  }
}
</style>
