import axios from './axios.service'

const method = 'banners'

class BannerHomeService {
  async getAll(queryData) {
    try {
      const params = {
        ...queryData,
        sorter: {
          ...queryData.sorter,
          column:
            queryData.sorter.column === 'time'
              ? 'begin_at'
              : queryData.sorter.column
        }
      }

      const response = await axios.get(`/${method}`, {
        params
      })
      return response.data
    } catch (error) {
      return error
    }
  }

  async getBanner(id) {
    try {
      const response = await axios.get(`/${method}/${id}`)
      return response.data
    } catch (error) {
      return error
    }
  }

  async updateStatus(rowClicked, status) {
    try {
      const response = await axios({
        method: 'put',
        url: `/${method}/status/${rowClicked.id}`,
        data: {
          status
        }
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateImageTitle(id, name) {
    try {
      const response = await axios({
        method: 'put',
        url: `/media/updatename/${id}`,
        data: {
          name
        }
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateBannerOrders(data) {
    try {
      const response = await axios({
        method: 'put',
        url: `/${method}/updateorder/order`,
        data: {
          data
        }
      })
      return response
    } catch (error) {
      return error
    }
  }

  async deleteBanner(rowClicked) {
    try {
      const response = await axios.delete(`/${method}/${rowClicked.id}`)
      return response
    } catch (error) {
      return error.response
    }
  }

  async storeBanner(data) {
    try {
      const response = await axios({
        method: 'post',
        url: `/${method}`,
        data
      })
      return response
    } catch (error) {
      return error.response
    }
  }

  async updateBanner(id, data) {
    try {
      const response = await axios({
        method: 'put',
        url: `/${method}/${id}`,
        data
      })
      return response
    } catch (error) {
      return error.response
    }
  }
}

export default new BannerHomeService()
